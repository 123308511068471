import { FLASH_MIMETYPE, FUTURESPLASH_MIMETYPE, FLASH7_AND_8_MIMETYPE, FLASH_MOVIE_MIMETYPE, isBuiltInContextMenuVisible, isFallbackElement, isScriptAccessAllowed, isSwfFilename, isYoutubeFlashSource, workaroundYoutubeMixedContent, RufflePlayer, } from "./ruffle-player";
import { registerElement } from "./register-element";
/**
 * A polyfill html element.
 *
 * This specific class tries to polyfill existing `<embed>` tags,
 * and should not be used. Prefer [[RufflePlayer]] instead.
 *
 * @internal
 */
export class RuffleEmbed extends RufflePlayer {
    /**
     * Constructs a new Ruffle flash player for insertion onto the page.
     *
     * This specific class tries to polyfill existing `<embed>` tags,
     * and should not be used. Prefer [[RufflePlayer]] instead.
     */
    constructor() {
        super();
    }
    /**
     * @ignore
     * @internal
     */
    connectedCallback() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
        super.connectedCallback();
        const src = this.attributes.getNamedItem("src");
        if (src) {
            const allowScriptAccess = (_b = (_a = this.attributes.getNamedItem("allowScriptAccess")) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : null;
            const menu = (_d = (_c = this.attributes.getNamedItem("menu")) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : null;
            // Kick off the SWF download.
            this.load({
                url: src.value,
                allowScriptAccess: isScriptAccessAllowed(allowScriptAccess, src.value),
                parameters: (_e = this.attributes.getNamedItem("flashvars")) === null || _e === void 0 ? void 0 : _e.value,
                backgroundColor: (_f = this.attributes.getNamedItem("bgcolor")) === null || _f === void 0 ? void 0 : _f.value,
                base: (_g = this.attributes.getNamedItem("base")) === null || _g === void 0 ? void 0 : _g.value,
                menu: isBuiltInContextMenuVisible(menu),
                salign: (_j = (_h = this.attributes.getNamedItem("salign")) === null || _h === void 0 ? void 0 : _h.value) !== null && _j !== void 0 ? _j : "",
                quality: (_l = (_k = this.attributes.getNamedItem("quality")) === null || _k === void 0 ? void 0 : _k.value) !== null && _l !== void 0 ? _l : "high",
                scale: (_o = (_m = this.attributes.getNamedItem("scale")) === null || _m === void 0 ? void 0 : _m.value) !== null && _o !== void 0 ? _o : "showAll",
                wmode: (_q = (_p = this.attributes.getNamedItem("wmode")) === null || _p === void 0 ? void 0 : _p.value) !== null && _q !== void 0 ? _q : "window" /* Window */,
            });
        }
    }
    /**
     * Polyfill of HTMLObjectElement.
     *
     * @ignore
     * @internal
     */
    get src() {
        var _a;
        return (_a = this.attributes.getNamedItem("src")) === null || _a === void 0 ? void 0 : _a.value;
    }
    /**
     * Polyfill of HTMLObjectElement.
     *
     * @ignore
     * @internal
     */
    set src(srcval) {
        if (srcval) {
            const attr = document.createAttribute("src");
            attr.value = srcval;
            this.attributes.setNamedItem(attr);
        }
        else {
            this.attributes.removeNamedItem("src");
        }
    }
    /**
     * @ignore
     * @internal
     */
    static get observedAttributes() {
        return ["src", "width", "height"];
    }
    /**
     * @ignore
     * @internal
     */
    attributeChangedCallback(name, oldValue, newValue) {
        var _a;
        super.attributeChangedCallback(name, oldValue, newValue);
        if (this.isConnected && name === "src") {
            let parameters;
            const flashvars = this.attributes.getNamedItem("flashvars");
            if (flashvars) {
                parameters = flashvars.value;
            }
            const src = this.attributes.getNamedItem("src");
            if (src) {
                this.load({
                    url: src.value,
                    parameters,
                    base: (_a = this.attributes.getNamedItem("base")) === null || _a === void 0 ? void 0 : _a.value,
                });
            }
        }
    }
    /**
     * Checks if the given element may be polyfilled with this one.
     *
     * @param elem Element to check.
     * @returns True if the element looks like a flash embed.
     */
    static isInterdictable(elem) {
        // Don't polyfill if the element is inside a specific node.
        if (isFallbackElement(elem)) {
            return false;
        }
        // Don't polyfill when no file is specified.
        if (!elem.getAttribute("src")) {
            return false;
        }
        // Don't polyfill when the file is a Youtube Flash source.
        if (isYoutubeFlashSource(elem.getAttribute("src"))) {
            // Workaround YouTube mixed content; this isn't what browsers do automatically, but while we're here, we may as well
            workaroundYoutubeMixedContent(elem, "src");
            return false;
        }
        // Check for MIME type.
        const type = elem.getAttribute("type");
        if (!type) {
            // If no MIME type is specified, polyfill if movie is an SWF file.
            return isSwfFilename(elem.getAttribute("src"));
        }
        switch (type.toLowerCase()) {
            case FLASH_MIMETYPE.toLowerCase():
            case FUTURESPLASH_MIMETYPE.toLowerCase():
            case FLASH7_AND_8_MIMETYPE.toLowerCase():
            case FLASH_MOVIE_MIMETYPE.toLowerCase():
                return true;
            default:
                return false;
        }
    }
    /**
     * Creates a RuffleEmbed that will polyfill and replace the given element.
     *
     * @param elem Element to replace.
     * @returns Created RuffleEmbed.
     */
    static fromNativeEmbedElement(elem) {
        const externalName = registerElement("ruffle-embed", RuffleEmbed);
        const ruffleObj = document.createElement(externalName);
        ruffleObj.copyElement(elem);
        return ruffleObj;
    }
}
